<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
        </b-row>

        <b-card
        >
          <b-form
                  @submit.prevent="searchByCondition"
                  @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="销售单编号:"
                        label-for="orderNo"
                        label-size="sm"
                >
                  <b-form-input
                          v-model="state.condition.orderNo"
                          name="orderNo"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="销售单编号"
                  />
                </b-form-group>
              </b-col>

              <!--团队-->
              <b-col
                      cols="12"
                      md="4"
              >
                <xy-input-button
                        label="团队名称"
                        type="input"
                        v-on:change="fromChildren($event,['storeName','storeId'])"
                        :params="['storeName','storeId']"
                        :value="state.condition.storeName"
                        modalName="团队"
                        placeholder="点击选择团队名称"
                >
                </xy-input-button>
              </b-col>


              <!--终端客户-->
              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                        label="终端客户"
                        type="input"
                        v-on:change="fromChildren($event,['channelName','channelId'])"
                        :params="['channelName','channelId']"
                        :value="state.condition.channelName"
                        modalName="终端客户"
                        placeholder="点击选择终端客户"
                >
                </xy-input-button>
              </b-col>

              <!--审核状态-->
              <b-col md="4" cols="12">
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="审核状态:"
                        label-for="deposit_status"
                        label-size="sm"
                        class="mb-1"
                >

                  <v-select
                          id="receipt_type"
                          :options="getCodeOptions('deposit_status')"
                          :clearable="true"
                          v-model="state.condition.status"
                          class="select-size-sm"
                          placeholder="请选择审核状态"
                          :reduce="option => option.value"
                  >
                  </v-select>
                </b-form-group>
              </b-col>


              <b-col
                      cols="12"
                      md="12"
                      class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(status)="data">
          {{ getCodeLabel('deposit_status', data.item.status) }}
        </template>

        <template #cell(remark)="data">
          <div :id="`remark-${data.item.id}`">
                     <span v-if="data.item.remark">
                       {{ data.item.remark.substring(0, 6) }}
                     <span v-if="data.item.remark.length>6">...</span>
                     </span>
          </div>
          <b-tooltip :target="`remark-${data.item.id}`" placement="top">
            {{ data.item.remark }}
          </b-tooltip>
        </template>

        <template #cell(add_time)="data">
          {{ toTime(data.item.add_time) }}
        </template>

        <template #cell(creator)="data">
          {{getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(modify_time)="data">
         {{ toTime(data.item.modify_time) }}
        </template>

        <template #cell(updator)="data">
          {{getCodeLabel('user', data.item.updator) }}
        </template>

        <template #cell(order_id)="data">
          <a target="_blank" :href="toURL(data.item.order_id)">
             {{data.item.ext.orderNo}}
          </a>
        </template>

        <template #cell(store_id)="data">
            {{data.item.ext.storeName}}
        </template>

        <template #cell(channel_id)="data">
          {{data.item.ext.channelName}}
        </template>

        <template #cell(deposit_money)="data">
          {{data.item.ext.depositMoney}}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
<!--多菜单-->
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <div v-if="data.item.status == 0">
                <b-dropdown-item @click="changeStatus(data.item,1)" >
                  <feather-icon icon="FileTextIcon"/>
                  <span class="align-middle ml-50">审核通过</span>
                </b-dropdown-item>

                <b-dropdown-item @click="changeStatus(data.item,2)" >
                  <feather-icon icon="FileTextIcon"/>
                  <span class="align-middle ml-50">驳回</span>
                </b-dropdown-item>
            </div>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <b-modal
              id="modal-remark"
              ok-only
              ok-title="确认"
              @ok="saveRemark"
              cancel-title="取消"
              centered
              size="lg"
              title="驳回原因"
              ref="clerkMemoModal"
      >
        <b-card>
          <b-form-textarea
                  id="textarea"
                  v-model="remarkInfo"
                  rows="3"
                  max-rows="6"
          />
        </b-card>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, reactive, onMounted, toRefs } from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import salesorderdepositUseList from './salesorderdepositUseList'
import salesorderdepositStore from './salesorderdepositStore'
import Notification from 'element-ui/packages/notification'
import XyInputButton from '@/views/components/xy/XyInputButton'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton
  },
  methods: {
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('salesorderdeposit/updateCondition', this.state.condition)
      this.refetchData()
    },
  },
  data() {
    return {
      remarkInfo: '',
      sodeId: 0,
    }
  },
  setup() {
    // Register module
    if (!store.hasModule('salesorderdeposit')) store.registerModule('salesorderdeposit', salesorderdepositStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesorderdeposit')) store.unregisterModule('salesorderdeposit')
    })

    const state = reactive({
      condition: {},
    })

    onMounted(() => {
      state.condition = store.getters['salesorderdeposit/getCondition']
    })

    const changeStatus = function (data,status) {
      // 通过
      if(status === 1){
        store.dispatch('salesorderdeposit/changeStatus', {id:data.sode_id,status:status}).then(res => {
          if (res.data.code === 0) {
            Notification({
              title: '成功',
              message: '操作完成!',
              type: 'success'
            });
            this.refetchData()
          } else {
            Notification({
              title: '失败',
              message: res.data.data,
              type: 'error'
            });
          }
        })
      }else if (status === 2){  //驳回填写原因并保存
        this.sodeId = data.sode_id;
        this.$refs['clerkMemoModal'].show()
      }
    }

   const toURL = function (urlId) {
       return "/apps/offlinesalesorder/list?id=" + urlId
   }


    const saveRemark = function () {
      if(isEmpty(this.remarkInfo)){
        Notification({
          title: '失败',
          message: '请填写驳回原因!',
          type: 'error'
        });
        return
      }else {
        store.dispatch('salesorderdeposit/changeStatus', {
          id: this.sodeId,
          status: 2,
          remark: this.remarkInfo
        })
                .then(res => {
                  if (res.data.code === 0) {
                    Notification({
                      title: '成功',
                      message: '驳回成功!',
                      type: 'success'
                    });
                    this.refetchData()
                  } else {
                    Notification({
                      title: '失败',
                      message: res.data.data,
                      type: 'error'
                    });
                  }
                })
      }
    }


    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.state.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = salesorderdepositUseList()

    return {
      ...toRefs(state),
      state,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
      changeStatus,
      toURL,
      saveRemark,
      fromChildren
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
